var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-list-item", {
    attrs: { "three-line": "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var active = ref.active
          return [
            _vm.selectable
              ? _c(
                  "v-list-item-action",
                  { staticStyle: { "margin-top": "10px" } },
                  [
                    _c("v-checkbox", {
                      attrs: {
                        color: _vm.color + " darken-1",
                        "input-value": active,
                        value: _vm.contact.id
                      },
                      model: {
                        value: _vm.selected,
                        callback: function($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected"
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "v-list-item-avatar",
                  [
                    _c(
                      "v-avatar",
                      { attrs: { size: "40", color: _vm.color } },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "white--text", attrs: { large: "" } },
                          [_vm._v("mdi-account")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
            _c(
              "v-list-item-content",
              [
                _c(
                  "v-list-item-title",
                  { staticClass: "mb-1 d-flex" },
                  [
                    _vm._v(_vm._s(_vm.contact.name) + " "),
                    _c("v-spacer"),
                    _vm.label
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ml-2",
                            attrs: { small: "", color: _vm.color }
                          },
                          [_vm._v(_vm._s(_vm.label))]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm.type
                  ? _c("v-list-item-subtitle", { staticClass: "mt-n2" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-subtitle-2 font-weight-light text-uppercase"
                        },
                        [_vm._v(_vm._s(_vm.type))]
                      )
                    ])
                  : _vm._e(),
                _c("v-divider"),
                _vm._l(_vm.contactFields, function(field, index) {
                  return [
                    _c(
                      "v-list-item-subtitle",
                      { key: index },
                      [
                        _c("contact-view-item", {
                          attrs: {
                            color: _vm.color,
                            field: field,
                            contact: _vm.contact
                          }
                        })
                      ],
                      1
                    )
                  ]
                })
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.items, function(item, index) {
        return [
          _c("contact-view", {
            key: index,
            attrs: { contact: item, selectable: "" },
            on: { selected: _vm.updateContacts }
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <template v-for="(item, index) in items">
            <contact-view
                :contact="item"
                :key="index"
                selectable
                @selected="updateContacts"
            ></contact-view>
        </template>
    </div>
</template>
<script>
import ContactView from "@components/screen/components/Contact/ContactView";
export default {
    name: "employer-landlord-search-results",
    components: {
        ContactView,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        value: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            contacts: this.value,
        };
    },
    methods: {
        updateContacts(contact, add) {
            if (add) {
                this.contacts.push(contact);
            } else {
                this.contacts.splice(
                    this.contacts.findIndex((item) => item.id === contact.id),
                    1
                );
            }
            this.$emit("update", this.contacts);
        },
    },
};
</script>

<style scoped>
</style>
<template>
    <v-list-item three-line>
        <template v-slot:default="{ active }">
            <v-list-item-action v-if="selectable" style="margin-top: 10px">
                <v-checkbox
                    v-model="selected"
                    :color="`${color} darken-1`"
                    :input-value="active"
                    :value="contact.id"
                ></v-checkbox>
            </v-list-item-action>
            <v-list-item-avatar v-else>
                <v-avatar size="40" :color="color">
                    <v-icon large class="white--text">mdi-account</v-icon>
                </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="mb-1 d-flex"
                    >{{ contact.name }}
                    <v-spacer></v-spacer>
                    <v-chip class="ml-2" small v-if="label" :color="color">{{
                        label
                    }}</v-chip>
                </v-list-item-title>
                <!-- Label is Family, Landlord, etc. -->
                <v-list-item-subtitle v-if="type" class="mt-n2">
                    <span
                        class="text-subtitle-2 font-weight-light text-uppercase"
                        >{{ type }}</span
                    >
                </v-list-item-subtitle>
                <v-divider></v-divider>
                <template v-for="(field, index) in contactFields">
                    <v-list-item-subtitle :key="index">
                        <contact-view-item
                            :color="color"
                            :field="field"
                            :contact="contact"
                        ></contact-view-item>
                    </v-list-item-subtitle>
                </template>
            </v-list-item-content>
        </template>
    </v-list-item>
</template>
<script>
export default {
    name: "contact-view",
    components: {
        ContactViewItem: () => import("./ContactViewItem"),
    },
    props: {
        label: {
            type: String,
            required: false,
            default: "",
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
        type: {
            type: String,
            required: false,
            default: "",
        },
        contact: {
            type: Object,
            required: true,
        },
        selectable: {
            type: Boolean,
            required: false,
            default: false,
        },
        exclude: {
            type: Array,
            required: false,
            default: () => {
                return ["is_primary", "source", "address", "ratings.rating"];
            },
        },
    },
    data() {
        return {
            selected: null,
            fields: [
                {
                    name: "address",
                    icon: "mdi-home-map-marker",
                },
                {
                    name: "email",
                    icon: "mdi-email",
                    href: "mailto:",
                },
                {
                    name: "phone",
                    icon: "mdi-phone",
                    href: "tel:",
                },
                {
                    name: "fax",
                    icon: "mdi-fax",
                },
                {
                    name: "source",
                    icon: "mdi-account-arrow-right",
                    transform: (v) => `Source: ${v.toUpperCase()}`,
                },
                {
                    name: "is_primary",
                    icon: "mdi-account-check",
                    transform: (v) => `Primary Contact: ${v ? "Yes" : "No"}`,
                },
                {
                    name: "ratings.rating",
                    type: "rating",
                },
            ],
        };
    },
    computed: {
        contactFields: {
            immediate: true,
            get() {
                return this.exclude.length === 0
                    ? this.fields
                    : this.fields.filter(
                          (item) => !this.exclude.includes(item.name)
                      );
            },
        },
    },
    watch: {
        selected(val) {
            this.$emit("selected", this.contact, val ? true : false);
        },
    },
    methods: {},
};
</script>

<style scoped>
</style>